<template>
  <div class="overflow-scroll">
    <div style="min-width: 1150px">
      <a-card :bordered="false">
        <div style="margin-bottom: 24px;padding-bottom: 24px">
          <label class="listTitle">水电燃表信息</label>
        </div>
        <div class="table-operator">
          <a-form layout="inline" :form="form">
            <a-row :gutter="30" style="line-height: 4">
              <a-col :md="8" :sm="24">
                <a-form-item label="所属门店">
                  <a-select
                    @change="getKitchen"
                    style="width: 240px"
                    v-decorator="[
                      'facilityId',
                      {
                        rules: [{ required: true, message: '请选择' }],
                        initialValue: handle.facilityId
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in facilityList"
                      :key="item.id"
                    >
                      {{ item.optionText }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="设备类型">
                  <a-select
                    style="width: 240px"
                    v-decorator="[
                      'type',
                      {
                        rules: [{ required: true, message: '请选择' }],
                        initialValue: handle.type
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in deviceTypeList"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否智能设备">
                  <a-select
                    style="width: 240px"
                    v-decorator="[
                      'enableSmartDevice',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择！'
                          }
                        ],
                        initialValue: handle.enableSmartDevice
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in smartList" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="属性">
                  <a-select
                    @change="ChangeuseScope"
                    style="width: 240px"
                    v-decorator="[
                      'useScope',
                      {
                        rules: [{ required: true, message: '请选择' }],
                        initialValue: handle.useScope
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in useScopeList"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="倍率">
                  <a-input
                    style="width: 240px"
                    v-decorator="[
                      'ratio',
                      {
                        rules: [
                          {
                            required: true,
                            pattern: /^[0-9]*[1-9][0-9]*$/,
                            message: '请输入正整数！'
                          }
                        ],
                        initialValue: handle.ratio
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="设备名称">
                  <a-input
                    style="width: 240px"
                    v-decorator="[
                      'deviceName',
                      {
                        rules: [{ required: true, message: '请输入' }],
                        initialValue: handle.deviceName
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col v-if="this.Iskitchen" :md="8" :sm="24">
                <a-form-item label="所属档口">
                  <a-select
                    style="width: 240px"
                    v-decorator="[
                      'kitchenId',
                      {
                        rules: [{ required: true, message: '请选择' }],
                        initialValue: handle.kitchenId
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in kitchList" :key="item.id">
                      {{ item.code }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-card>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button type="primary" style="margin-left: 8px" @click="conserve">
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
  
import Bus from "@/bus";
import axios from "axios";

export default {
  name: "hydropowerConfigureEdit",
  data() {
    return {
      form: this.$form.createForm(this),
      // 拿到列表的值
      handle: { ...this.$route.query },
      //档口
      kitchList: [],
      // 档口的显示
      Iskitchen: false,
      //所属门店
      facilityList: [],
      //设备类型
      deviceTypeList: [
        { id: 1, name: "水表" },
        { id: 2, name: "电表" },
        { id: 3, name: "燃气表" }
      ],
      // 是否智能设备
      useScopeList: [
        { id: 1, name: "公摊" },
        { id: 2, name: "自用" },
        { id: 3, name: "总表" }
      ],
      // 是否智能设备
      smartList: [
        { id: "Y", name: "是" },
        { id: "N", name: "否" }
      ]
    };
  },
  created() {
    this.getInformation();
    this.getKitchen(this.handle.facilityId);
    if (this.handle.useScope == 2) {
      this.Iskitchen = true;
    }
    Bus.$on("getTarget", target => {
      // this.$router.push({
      //   path: "/basicConfiguration/hydropowerConfigure"
      // });
      this.getInformation();
      this.getKitchen(this.handle.facilityId);
      if (this.handle.useScope == 2) {
        this.Iskitchen = true;
      }
    });
  },
  methods: {
    // 选择公摊和自用之后的操作
    ChangeuseScope(val) {
      if (val == 1 || val == 3) {
        this.Iskitchen = false;
        // 如果查询到的档口为空，清空它们之前的选中
        const {
          form: { setFieldsValue }
        } = this;
        setFieldsValue({
          kitchenId: ""
        });
      } else {
        this.Iskitchen = true;
      }
    },
    //获取所有枚举信息
    getInformation() {
      this.getFacility();
    },
    // 所属门店
    getFacility() {
      axios({
        url:   this.$baseUrl + "/facility/querySelect",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 根据所在门店获取关联档口
    getKitchen(val) {
      // 如果查询到的档口为空，清空它们之前的选中
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        kitchenId: ""
      });
      this.kitchList = [];
      axios({
        method: "GET",
        url:   this.$baseUrl + "/kitchen/querySelect",
        params: {
          facilityId: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.kitchList = res.data.rows;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    cancel() {
      // 点击取消
      this.$router.push({ path: "/basicConfiguration/hydropowerConfigure" });
    },
    //编辑
    brandCreate(val) {
      axios({
        method: "POST",
        url:   this.$baseUrl + "/weg/modify",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val,
          id: this.handle.id
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$router.push({
              path: "/basicConfiguration/hydropowerConfigure"
            });
            this.$message.success("修改成功！");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    conserve() {
      //点击保存
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.brandCreate(values);
        }
      });
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
}
</style>
